.items-table{
	tbody{
		
		tr{
			padding:0;
			border-radius: $radius;
			position: relative;
			box-shadow: 0 1px 0 0 #f8f8f8;
			@include transitionMedium;
			.dropdown{
				opacity:0;
			}
			&.active,
			&:hover{
				box-shadow: 0 4px 4px 0 rgba(0,0,0,0.25);
				z-index: 1;
				.dropdown{
					opacity:1;
				}	
			}
			th,
			td{
				border: 0;
				padding:18px 8px;
			}
		}
	}
	@include respond('phone') {
		width:500px;
	}	
}

.star-review{
	i{
		font-size:16px;
		&.text-gray{
			color:#d9d9d9;
		}
	}
	span{
		color:$black;
	}
}


// Form Head
.form-head{

	.breadcrumb{
		background:transparent;
		padding:0;
	}
	.search-area{
		max-width:300px;
		border-radius: 0;
		@include respond('laptop') {
			max-width:250px;
		}
		@include respond('phone') {
			max-width:250px;
		}
		.form-control{
			border: 0;
			height: 56px;
			padding: 10px 15px;
			font-size: 16px;
			background:#f1f1f1;
			border-radius:0;
			@include respond('laptop') {
				height: 41px;
				padding: 10px 15px;
				font-size: 13px;
			}
		}
		.input-group-append .input-group-text{
			background:#f1f1f1;
			padding: 0 5px  0 30px;
			@include respond('laptop') {
				padding: 0 5px  0 30px;
			}
			i {
				font-size: 20px;
				color:$body-color;
				@include respond('laptop') {
					font-size: 18px;
				}
			}
		}
	}
	.dropdown {
		.btn{
			
			i{
				transform: scale(1.3);
				-moz-transform: scale(1.3);
				-webkit-transform: scale(1.3);
				-ms-transform: scale(1.3);
				-o-transform: scale(1.3);
				display: inline-block;
			}
		}
		&:after{
			color:$primary;
			font-size:25px;
		}
	}
	.btn i{
		line-height: 1;
		transform: scale(1.3);
		display: inline-block;
		margin-right: 5px;
	}
	.btn-outline-primary{
		border-color:#eee;
		&:hover{
			border-color:$primary;
		}
	}
	& > *{
		@include respond('phone-land') {
			margin-right:5px !important;
			margin-bottom:10px;	
			display: inline-block;	
			vertical-align: baseline;
			margin-left:0 !important;
		}
	}
	@include respond('phone-land') {
		display:block !important;
	}
}
.rounded{
	border-radius:$radius !important;
}
.btn-link{
    font-weight: 500;
    font-size: 16px;
	@include respond('laptop') {
		font-size: 14px;
	}
}
.deznav .copyright{
	font-size:14px;
	padding: 0 45px;
	margin-top: 40px;
	margin-bottom: 40px;
	position: absolute;
	bottom: 0;
	
	p{
		margin-bottom:10px;
		strong{
			display: block;
		}
	}
	@include respond('laptop') {
		padding:0 25px;
	}
}
.widget-buyer {
    .media {
        img {
            width: 30px;
        }
        .media-body {
            h5 {
                font-size: 14px;
                margin-bottom: 0px;
            }
            p {
                font-size: 13px;
            }
        }
    }
}

.recentOrderTable {
    table {
        margin-bottom: 0;
        overflow: hidden;
        thead {
            th {
                font-size: 12px;
            }
        }
        tbody {
            td {
                color: $dark;
                font-weight: 500;
                line-height: 40px;
                @at-root [data-theme-version="dark"] & {
                    color: $d-ctl;
                }
                img {
                    border-radius: 50px;
                }
                .custom-dropdown {
                    i {
                        padding: 0 10px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
.activity_overview{
	.nav-tabs{
		border-bottom:1px solid rgba(255,255,255,0.2) !important;
	}
	.custom-tab-1 .nav-link{
		color:rgba(255,255,255,0.5);
		padding-left: 0;
		padding-right: 0;
		margin-right: 30px;
		
		&:hover,
		&.active{
			background:transparent;
			color:#fff;
			border-color: #fff;
		}
	}
}
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    background-color: transparent!important;
    opacity: 0.9;
}

.chart-link {
    @include respond('phone') {
        display: flex;
        align-items: center;
    }
    @include respond('tab-land') {
        display: flex;
        align-items: center;
    }
    @include respond('desktop') {
        display: flex;
        align-items: center;
    }
    .week-link {
        display: inline-block;
        // @include respond('tab-land'){
        //     display: flex;
        // }
    }
    a {
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        margin-right: 10px;
        i {
            font-size: 13px;
            &.text-primary {
                color: $primary;
            }
            &.text-muted {
                color: #DAE2F3!important;
            }
        }
    }
    .form-control {
        max-width: 97px;
        display: inline-block;
    }
}
.input-group {
	&.input-group-lg{
		.form-control,.input-group-text{
			height:80px;
			padding:10px 30px;
			@include respond('phone') {
				height:50px;
				padding: 10px 15px;
				font-size:14px;
			}
		}
		.input-group-text{
			width:200px;
			text-align:left;
			@include respond('phone') {
				width:120px;
				padding: 10px 15px;
				height:50px;
				font-size:14px;
			}
		}
	}
}
.card-tabs {
    .nav-tabs {
		border-bottom: 0px;
		background: transparent;
		padding: 0;
		border-radius: 0;
		flex-wrap: unset;
			
        .nav-link {
            border-radius:0;
		    padding: 12px 20px;
			font-weight: 500;
			position:relative;
			background:transparent;
			font-size:14px;
			border:0;
			border-bottom:1px solid #C0C0C0;
			&:after{
				position:absolute;
				bottom:-4px;
				left:50%;
				transform:translatex(-50%);
				content:"";
				height:4px;
				width:0;
				@include transitionMedium;
				background:$primary;
			}
            &.active {
                color: $primary;
				&:after{
					width:100%;
				}
            }
			@include respond('laptop') {
				padding: 8px 15px;
				font-weight: 400;
				font-size: 13px;
			}
        }
		&.tabs-lg{
			.nav-link {
				padding:23px 30px;
				font-size:16px;
				@include respond('phone-land') {
					padding:10px 15px;
					font-size:14px;
				}	
			}
		}	
    }
	&.style-1{
		.nav-tabs{
			.nav-link{
				font-size:16px;
				color:$black;
				&:after{
					content:none;
				}
				span{
					position:absolute;
					bottom:-4px;
					left:50%;
					transform:translatex(-50%);
					content:"";
					height:4px;
					width:0;
					@include transitionMedium;
				}
				&.active{
					span{
						width:100%;
					}
				}
			}
		}
	}
	
}

.revenue-chart .apexcharts-legend.apexcharts-align-left .apexcharts-legend-series{
	display:flex !important;
	margin-right: 30px !important;
	margin-bottom: 30px !important;
}
.revenue-chart-bar{
	margin-bottom:-30px;
	
	@include respond('phone') {
		margin-bottom: 20px;
		float: none;
		padding-left: 20px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
	.revenue-chart-bar{
		margin-bottom: 20px;
		float: none;
		padding-left: 20px;
	}
}
#activeUser {
    height: 215px!important;
}
#activeUser {
    height: 180px!important;
}
span#counter {
    font-size: 30px;
    font-weight: 700;
    color: $primary;
}
.social-graph-wrapper {
    text-align: center;
    padding: 20px;
    position: relative;
    color: $white;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
	
    &.widget-facebook {
        background-color: $facebook;
    }
    &.widget-twitter {
        background-color: $twitter;
    }
    &.widget-linkedin {
        background-color: $linkedin;
    }
    &.widget-googleplus {
        background-color: $google-plus;
    }
    .s-icon {
        font-size: 24px;
        position: relative;
        // top: 0.625rem;
        padding: 0 10px;
    }
}
.recent-stats i{
    font-size: 11px;
    position: relative;
    top: -1px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
	#lineChart_3Kk{
		height: 140px !important;
	}
}
/* add menu sidebar */
.drum-box{
	background: rgb(80,97,255); 
	background: -moz-linear-gradient(45deg,  rgba(80,97,255,1) 0%, rgba(49,206,255,1) 100%); 
	background: -webkit-linear-gradient(45deg,  rgba(80,97,255,1) 0%,rgba(49,206,255,1) 100%);
	background: linear-gradient(45deg,  rgba(80,97,255,1) 0%,rgba(49,206,255,1) 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5061ff', endColorstr='#31ceff',GradientType=1 );
	padding: 85px 20px 20px;
    border-radius: $radius;
	position:relative;
    margin: 50px 40px 30px;
	
	p{
		font-size:16px;
		line-height:1.4;
		padding-right: 80px;
		font-weight:300;
		color:#fff;
	}
	a{
		color:#BCD7FF;
	}
	img{
		position: absolute;
		top: -38px;
		right: -20px;
	}
	&:after{
		position:absolute;
		background-image:url('./../../images/dots.svg');
		background-repeat:no-repeat;
		bottom:10px;
		right:20px;
		content:"";
		height:30px;
		width:25px;
	}
	&:before{
		position:absolute;
		background-image:url('./../../images/dots.png');
		background-repeat:no-repeat;
		top:10px;
		left:0px;
		content:"";
		height:60px;
		width:60px;
	}
	@include respond('laptop') {
		 margin: 50px 25px 30px;
	}
	@include respond('phone') {
		 margin: 20px 25px 30px;
		 img{
			margin-left: 90px;
		}
	}
}




.donut-chart-sale{
	position:relative;
	z-index:1;
	small{
		font-size: 16px;
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		display: flex;
		align-items: center;
		top: 0;
		justify-content: center;
		font-weight: 600;
	}
	.circle{
		height:91px;
		width:91px;
		z-index:-1;
		content:"";
		position:absolute;
		border-radius:100%;
		top:50%;
		left:50%;
		transform:translate(-50%,-50%);
	}
}
.donut-chart-sale2{
	position:relative;
	z-index:1;
	small{
		font-size: 16px;
		position: absolute;
		width: 75%;
		height: 75%;
		box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
		left: 50%;
		border-radius: 76px;
		/* padding: 12px; */
		display: flex;
		/* margin: 0px; */
		align-items: center;
		top: 50%;
		transform: translate(-50%, -50%);
		justify-content: center;
		font-weight: 600;
	}
}
.star-icons {
	i{
		font-size: 22px;
		color: $primary;
		&:last-child{
			color:rgba($primary,0.1);
		}
	}
}
/* order manage */ 
.order-manage{
	background:$success-light;
	border-radius:12px;
}




.max-h80{
	max-height:80px;
}
.ml--12{
	margin-left:-12px;
}

.featured-menus.owl-carousel{
	.food-image{
		width:160px;
		@include respond ('phone'){
			width:100%;
			height:200px;
			object-fit:cover;
			margin-bottom:20px;
		}
	}
	.profile-image{
		width:30px;
	}
	.owl-prev,
	.owl-next {
		height: 68px;
		width: 68px;
		background: $white;
		color:$primary;
		line-height: 68px;
		border-radius:100%;
		position: absolute;
		font-size: 35px;
		text-align: center;
		top: 50%;
		box-shadow: 0px 0px 30px 5px rgba(0,0,0,0.1);
		transform: translateY(-50%);
		cursor: pointer;
		z-index: 1 !important;
	}
	.owl-prev{
		left: -34px;
	}
	.owl-next {
		right: -34px;
	}
	@include respond ('phone'){
		.owl-prev{
			left: 0;
		}
		.owl-next {
			right: 0;
		}
	}
}

.search-area{
	width:300px;
	.form-control {
		background: #F3F3F3;
		border-width: 0px;
		padding-left: 20px;
		border-radius: $radius 0 0 $radius;
	}
	.input-group-text {
		background: #F3F3F3;
		border-width: 0px;
		padding-right: 25px;
		border-radius: 0 $radius $radius 0;
		cursor: pointer;
	}
}
.image-placeholder {
    position: relative;
    max-width: 130px;
    margin: 10px auto;
    .avatar-edit {
        position: absolute;
        right: -10px;
        z-index: 1;
        top: 0px;
        input {
            display: none;
            + label {
                display: inline-block;
                width: 34px;
                height: 34px;
                margin-bottom: 0;
                border-radius: 100%;
                background: #FFFFFF;
                border: 1px solid transparent;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                cursor: pointer;
                font-weight: normal;
                transition: all .2s ease-in-out;
                &:hover {
                    background: #f1f1f1;
                    border-color: #d6d6d6;
                }
                &:after {
                    content: "\f040";
                    font-family: 'FontAwesome';
                    color: #757575;
                    position: absolute;
                    top:4px;
                    left: 0;
                    right: 0;
                    text-align: center;
                    margin: auto;
                }
            }
        }
    }
    .avatar-preview {
        width: 130px;
        height: 95px;
        position: relative;
        border-radius: 30px;
        border: 3px solid #F8F8F8;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        & > div {
            width: 100%;
			height: 100%;
            border-radius: 30px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
			overflow: hidden;
			img{
				height: 100%;
				width: 100%;
				object-fit: cover
			}
        }
    }
}
.project-boxed {
    overflow: hidden;
}

.required{
	color: $danger;
}
.post-input{
    margin-bottom: 1.875rem;
    .form-control{
        // height:47px;
        font-weight: 400;
        margin: 0.9375rem 0;
    }
	.btn-social{
		font-size: 1.25rem;
		height: 3.4375rem;
		display:inline-block;
		padding: 0;
		text-align:center;
		border-radius:$radius;
		color:$white;
		width: 3.4375rem;
		line-height: 3.4375rem;
		&.facebook{
			background-color: #3B5998;
		}
		&.google-plus{
			background-color: #DE4E43;
		}
		&.linkedin{
			background-color: #007BB6;
		}
		&.instagram{
			background-color: #8A5A4E;
		}
		&.twitter{
			background-color: #1EA1F3;
		}
		&.youtube{
			background-color: #CE201F;
		}
		&.whatsapp{
			background-color: #01C854;
		}
		i{
			margin:0!important;
		}
	}
    
}  

.icon-false{
	&:after{
		content: none;
	}
}