.container {
  position: relative;
  width: 30%;
}

/* .image {
  opacity: 1;
  display: block;
 
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
} */

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 165%;
  width: 70px;
  height: 70px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.container:hover .image {
  opacity: 0.3;
}

.container:hover .middle {
  opacity: 1;
}

.text {
  color: black;
  font-size: 16px;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.customfileinput::-webkit-file-upload-button {
  visibility: hidden;
}

.customfileinput::before {
  content: "files";
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
  display: none;
}
/* input[type='file'] {
  opacity: 0;
} */
.customfileinput:hover::before {
  border-color: black;
}
.customfileinput:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
